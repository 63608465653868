:global {
  .IDevsol-header.ant-layout-header {
    padding: 0px;
    background: transparent;
    box-shadow: 0px 8px 24px rgba(94, 119, 249, 0.1);
    position: fixed;
    width: 100%;
    z-index: 4;
    height: 84px;

    .IDevSol-logo {
      float: left;
      line-height: 84px;
    }

    .IDevSol-navBar {
      background-color: transparent;
      color: #ffffff;
      line-height: 84px;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      list-style: none;
      display: flex;

      li {
        padding: 0px 20px;

        a {
          position: relative;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #FFFFFF;
        }

        a.active {
          font-weight: 500;
        }

        a.active::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(90.26deg, #ed4c5c 19.3%, #ffffff 78.44%);
          border-radius: 4px;
          height: 4px;
          border-bottom: none;
          bottom: -5px;
        }
      }
    }
  }

  #header-scrolled.IDevsol-header.ant-layout-header {
    .IDevSol-navBar>li a {
      color: #c4c4c4;
    }

    li a.active {
      color: #be3052;
    }

    li a.active:hover {
      color: #be3052;
    }

    .menu-btn.ant-btn {
      svg {
        path {
          stroke: #463966;
        }
      }
    }
  }

  .menu-drawer.ant-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-header {
        padding: 0px;
        background-color: #BE3052;

        .ant-drawer-header-title {
          display: block;
          position: absolute;
          right: 0;
          top: 20px;
          margin-bottom: 0.5rem;

          .anticon {
            color: #ffffff;
            font-size: 24px;
          }
        }
      }

      .ant-drawer-content {
        background: #BE3052;

        a {
          position: relative;
          font-style: normal;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.25rem;
          color: #FFFFFF;
        }

        a.active::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(90.26deg, #ed4c5c 19.3%, #ffffff 78.44%);
          border-radius: 4px;
          height: 4px;
          border-bottom: none;
          bottom: -5px;
        }
      }
    }
  }
}

.bg-white {
  background-color: #ffffff;
  height: 84px;
  width: 100%;
}

.flex-box {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  align-items: center;
}

.drawer {
  width: fit-content;
  margin-left: auto;
  height: 84px;
  display: flex;
  align-items: center;
}