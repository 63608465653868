.file-picker {
  background: #ffffff;
  border: 0px solid #cecece;
  box-sizing: border-box;
  border-radius: 8px;
  height: auto;
  max-width: 230px;
}
.file-wrapper {
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  border: 0.5px solid #cecece;
  height: auto;
  width: 100%;
}
