.contact-section {
  margin: 12rem 0px;
}

.lets-connect {
  margin-bottom: 8rem;

  .map-div {
    margin: 6rem 0px;
  }

  .contact-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.375rem;
    color: #213864;
    text-align: center;
    margin: 4px 0x;
  }

  .contact-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    color: #213864;
    text-align: center;
    height: 5px;
    // text-align: justify;
  }
}

.get-touch {
  padding: 6rem 0px;
  background-color: #e5e5e5;

  .form-div {
    margin-top: 4rem;
  }

  .btn-div {
    float: right;
    margin-top: 1.5rem;
  }
}

.description-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 1.56rem;
  line-height: 157.5%;
  letter-spacing: 0.004em;
  color: #213864;
}

.styled-title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.18rem;
  line-height: 3.25rem;
  color: #213864;
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.styled-title::after {
  width: 2rem;
  height: 2rem;
  opacity: 1;
  content: "";
  position: absolute;
  border-top: 0.875rem solid #be3052;
  border-right: 0.875rem solid #be3052;
  border-radius: 2px;
  right: -1.2rem;
  top: -0.68rem;
}

.text-left {
  text-align: left;
  width: fit-content;
}

.form-control {
  margin-bottom: 24px;
}
.error-msg {
  color: red;
}
