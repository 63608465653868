html {
  font-size: 16px;
}

.container-xl {
  max-width: 100%;
  margin: 0px auto;
  padding: 0px 12px;
}

@media screen and (max-width: 576px) {
  .container-xl {
    max-width: 100%;
  }

  html {
    font-size: 8px;
  }
}

@media screen and (min-width: 576px) {
  .container-xl {
    max-width: 100%;
  }

  html {
    font-size: 8px;
  }
}

@media screen and (min-width: 768px) {
  .container-xl {
    max-width: 100%;
  }

  html {
    font-size: 10px;
  }
}

@media screen and (min-width: 992px) {
  .container-xl {
    max-width: 100%;
  }

  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .container-xl {
    max-width: 1190px;
  }

  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1400px) {
  .container-xl {
    max-width: 1380px;
  }

  html {
    font-size: 16px;
  }
}

.text-center {
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.img-fluid {
  max-width: 100% !important;
  height: auto;
}

.ant-layout {
  background-color: #fff;
}