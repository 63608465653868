:global {
  .contact-us-btn.ant-btn {
    border-radius: 25px 0px 25px 25px;
    height: auto;
    border: solid 3px transparent;
    background-image: linear-gradient(#6e2d43, #043c5e),
      linear-gradient(#6e2d43, #043c5e);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #be3052;
    padding: 0.8rem 1.875rem;
    z-index: 1;
  }

  .contact-us-btn.ant-btn:active {
    box-shadow: 2px 1000px 1px #fff inset;
  }

  .service-btn.ant-btn {
    background: #be3052;
    border-radius: 25px 25px 25px 0px;
    height: auto;
    padding: 0.8rem 1.875rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #fff;
    border: solid 3px transparent;
    z-index: 1;
  }

  .service-btn.ant-btn:active {
    box-shadow: none;
  }

  .header-contact-us-btn.ant-btn {
    background: #be3052;
    border-radius: 12px;
    border-color: #be3052;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #fff;
    height: auto;
    padding: 00.8rem 1.875rem;
  }

  .header-contact-us-menu-btn.ant-btn {
    background: #213864;
    border-radius: 12px;
    border-color: #213864;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #fff;
    height: auto;
    padding: 00.8rem 1.875rem;
  }

  .menu-btn.an-btn {
    border: none;
    background-color: transparent;
  }

  .apply-btn.ant-btn {
    background: #be3052;
    border-radius: 50px;
    border-color: #be3052;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #fff;
    height: auto;
    padding: 00.8rem 1.875rem;
  }
}
