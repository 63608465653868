:global {
  .offer-card.ant-card {
    position: relative;

    .ant-card-body {
      padding: 12px;
    }
  }

  // .offer-card.ant-card .ant-card-body::after {
  //   content: "";
  //   width: 22px;
  //   height: 22px;
  //   position: absolute;
  //   left: -5px;
  //   bottom: -5px;
  //   border-radius: 2px;
  //   border-left: 5px solid #ed4c5c;
  //   border-bottom: 5px solid #ed4c5c;
  // }

  .offer-card.ant-card .ant-card-body::before {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 2px;
    border-right: 5px solid #ed4c5c;
    border-top: 5px solid #ed4c5c;
  }

  .team-card.ant-card {
    background-color: inherit;
  }

  .service-card.ant-card {
    position: relative;
  }

  #serviceRow .ant-col:not(:last-child):after {
    content: "";
    position: absolute;
    top: 20%;
    right: 0;
    border-right: 1px solid #8F8F8F;
    height: 100px;
  }

  @media only screen and (max-width: 576px) {
    #serviceRow .ant-col:not(:last-child):after {
      display: none;
    }
  }
}