.about-us-section {
  margin: 12rem 0px;
}

.vision-section {
  .vision-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.56rem;
    color: #213864;
  }

  .flexible-title {
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    color: #213864;
    margin-bottom: 0px;
  }

  .flex-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    color: #213864;
    margin-bottom: 0px;
  }

  .vision-cards-section {
    margin: 8rem 0px;

    .card-grid {
      display: grid;
      grid-template-columns: repeat(3, 0.23fr);
      row-gap: 3rem;
      justify-content: space-around;
    }

    .card-title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: #213864;
      margin-bottom: 0px;
    }

    .card-text {
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 157.5%;
      letter-spacing: 0.01em;
      color: #213864;
      margin-top: 12px;
      margin-bottom: 0px;
    }
  }

  @media only screen and (max-width: 576px) {
    .vision-cards-section {
      .card-grid {
        grid-template-columns: repeat(1, 0.8fr);
      }
    }
  }
}

.work-section {
  background-color: #e5e5e5;
  padding: 4.375rem 0px;

  .work-div {
    margin-top: 3rem;
  }

  .recent-project {
    font-style: normal;
    font-weight: 600;
    font-size: 2.18rem;
    color: #213864;
  }

  .project-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    color: #213864;
  }

  .read-more {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: rgba(190, 48, 82, 0.12);
  }

  .read-more-link {
    margin-left: -30px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #be3052;
  }
}

.our-professional {
  margin: 6rem 0px;

  .professional-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    text-align: center;
    color: #213864;
    margin-bottom: 0px;
  }
}

.description-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 1.56rem;
  line-height: 157.5%;
  letter-spacing: 0.004em;
  color: #213864;
}

.styled-title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.18rem;
  line-height: 3.25rem;
  color: #213864;
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  //   background-color: #be3052;
}

.styled-title::after {
  width: 2rem;
  height: 2rem;
  opacity: 1;
  content: "";
  position: absolute;
  border-top: 0.875rem solid #be3052;
  border-right: 0.875rem solid #be3052;
  border-radius: 2px;
  right: -1.2rem;
  top: -0.68rem;
}

.text-left {
  text-align: left;
  width: fit-content;
}
@media only screen and (max-width: 576px) {
  .text-left {
    text-align: center;
    width: 100%;
  }
}
