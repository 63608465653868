:global {
  .IDevsol-footer.ant-layout-footer {
    background: #463966;
    padding: 2rem 0px;
  }

  .footer-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.375rem;
    color: #fff;
    text-align: center;
  }
}