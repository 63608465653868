.lower-footer-bar {
  height: 3.75rem;
  background-color: #be3052;
  width: 100%;
}
.apply-now-bar {
  background-color: #dadad8;
}
.flex-div {
  display: flex;
  padding: 1.875rem 0px;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 1.125rem;
    font-weight: 600;
  }
}
h1 {
  font-size: 2.875rem;
  color: #213864;
  margin-bottom: 0;
}
p {
  // margin-top: -30px;
  color: #213864;
  font-size: 1rem;
}
.content-container {
  h2 {
    font-size: 2.1875rem;
    color: #213864;
    margin-top: 3.83rem;
  }
  p {
    font-size: 1.125rem;
  }
  li {
    font-size: 1.125rem;
  }
  .last-apply-now-div {
    margin-bottom: 16.875rem;
  }
}
