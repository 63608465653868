:global {
  .custom-input.ant-picker {
    background: #ffffff;
    border: 0.5px solid #cecece;
    box-sizing: border-box;
    border-radius: 8px;
    height: 54px;
    width: 100%;
  }
}
