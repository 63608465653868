:global {
  .sendEmail.ant-input-group {
    height: 48px;
    .ant-input {
      height: 48px;
      border: 1px solid #be3052;
    }
    .ant-input:focus{
        box-shadow: none;
    }
    .ant-btn {
      height: 48px;
      background: #be3052;
      border-color: #be3052;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #ffffff;
    }
  }
}
