:global {
    .custom-loader {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-spin {
            color: #BE3052;
            font-size: 1.5rem;

            .ant-spin-dot {
                .ant-spin-dot-item {
                    background-color: #BE3052;
                }
            }
        }
    }
}