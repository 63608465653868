:global {
    .job-title {
        font-style: normal;
        font-weight: 500;
        font-size: 1.56rem;
        color: #213864;

    }

    .job-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        color: #213864;
    }
}