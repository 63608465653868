.lower-footer-bar {
  height: 3.75rem;
  background-color: #be3052;
  width: 100%;
}

.apply-now-bar {
  background-color: #dadad8;
}

.flex-div {
  display: flex;
  padding: 1.875rem 0px;
  justify-content: center;
  align-items: center;
}

ul li {
  font-size: 1.5rem;
  font-weight: 500;
}


.get-touch {
  padding: 6rem 0px;

  .btn-div {
    float: right;
    margin-top: 1.5rem;
  }
}

.error-msg {
  color: red;
}

.form-control {
  margin-bottom: 1.5rem;
}

.personal-details {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #213864;
  margin-bottom: 0px;

}

.dot {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  background-color: #be3052;
}

.mb-3 {
  margin-bottom: 3rem;
}