:global {
  .IDevSol-content.ant-layout-content {
    min-height: calc(100vh - 128px);
  }

  .testimonial-tag.ant-tag {
    border-radius: 12px;
    padding: 1rem 1.5rem;
    font-size: 2rem;
    color: #ffffff;
  }

  .testimonial-cards {
    .slick-slide {
      padding: 0px 5rem;
    }

    @media only screen and (max-width: 600px) {
      .slick-slide {
        padding: 0px 2rem;
      }
    }
  }
}

.container-fluid {
  width: 100%;
  background-color: #ffffff;
}

.hero-section {
  margin: 12rem 0px;
  margin-bottom: 16rem;

  h1.page-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.875rem;
    background: linear-gradient(
      92.2deg,
      #872a3d 2.09%,
      #3a3450 32.45%,
      #043c5e 51.5%,
      #642f46 76.63%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hero-section-btn {
    margin-top: 1.375rem;
  }
}

.section-bg {
  position: absolute;
  right: 0;
  top: 0px;

  img {
    width: 100%;
  }
}

.do-best-section {
  margin: 6rem 0px;
  position: relative;

  #rock-left {
    position: absolute;
    top: -8rem;
    left: -40px;

    img {
      width: 80%;
    }
  }

  .about-solutions {
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    letter-spacing: 0.004em;
    color: #be3052;
    margin-bottom: 0px;
  }

  .Do-best {
    font-style: normal;
    font-weight: 600;
    font-size: 2.185rem;
    line-height: 3.25rem;
    color: #213864;
  }
}

.offer-section {
  margin: 1rem 0px;
  margin-bottom: 8rem;

  .card-grid {
    display: grid;
    grid-template-columns: repeat(3, 0.23fr);
    row-gap: 5rem;
    justify-content: space-around;
  }

  .offer-card {
    margin-top: 6rem;
  }

  .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #213864;
    margin-bottom: 0px;
  }

  .card-text {
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 157.5%;
    letter-spacing: 0.01em;
    color: #213864;
    margin-top: 12px;
    margin-bottom: 0px;
  }
}

.our-goal-section {
  position: relative;

  #right-rock {
    position: absolute;
    right: 0;
    top: -3rem;
    overflow: hidden;

    img {
      height: 230px;
    }
  }

  .our-goal {
    font-style: normal;
    font-weight: 600;
    font-size: 2.1875rem;
    line-height: 3.25rem;
    color: #213864;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  .our-goal::before {
    width: 2rem;
    height: 2rem;
    opacity: 1;
    content: "";
    position: absolute;
    border-left: 0.875rem solid #be3052;
    border-top: 0.875rem solid #be3052;
    border-radius: 2px;
    left: -1.2rem;
    top: -0.68rem;
  }

  .goal-data {
    margin: 7.5rem 0px;
  }

  .goal-img {
    max-width: 100%;
  }

  .organization-title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.185rem;
    line-height: 3.25rem;
    color: #be3052;
  }

  .read-more {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: rgba(237, 76, 92, 0.12);
  }

  .read-more-link {
    margin-left: -30px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #ed4c5c;
  }
}

.testimonial-section {
  margin: 10.5rem 0px;

  .testimonial-slider {
    margin-top: 6rem;
    overflow-x: hidden;
  }

  .test-card {
    position: relative;

    .test-bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      z-index: -1;
    }

    .card-body {
      padding: 1.5rem;
      z-index: 1;

      .slick-text {
        font-style: normal;
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 2.65rem;
        color: #242424;
        margin-bottom: 0px;
      }

      .testimonial-body {
        display: grid;
        grid-template-columns: 0.6fr 0.4fr;
        align-items: center;
        justify-content: space-between;
      }

      .client-style {
        background: #ffffff;
        box-shadow: 5.54717px 5.54717px 18.4906px rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        left: 50px;
        position: relative;
        padding: 1.5rem;
      }
    }
  }

  .smaller-card {
    padding: 1.5rem;

    .quote {
      margin: 0 auto;
      width: fit-content;
      margin-bottom: 0.875rem;
    }

    .smaller-text {
      font-weight: normal;
      font-size: 1rem;
      color: #213864;
      opacity: 0.8;

      margin-bottom: 0px;
    }
  }
}

.team-section {
  .our-team {
    font-style: normal;
    font-weight: 600;
    font-size: 2.18rem;
    line-height: 3.25rem;
    color: #213864;
    text-align: center;
  }

  .team-member {
    margin: 6rem 0px;
  }

  .team-img {
    width: 290px;
    height: 290px;
    border-radius: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: auto;
  }

  .member-name {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #213864;
    margin-top: 1.5rem;
    margin-bottom: 0px;
  }

  .member-role {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: #5e5873;
    margin-bottom: 2px;
  }
}

.email-section {
  background: #dadad8;
  padding: 3rem 0px;

  .wants-more {
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    color: #be3052;
    margin-bottom: 2px;
  }

  .send-email {
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    color: #043c5e;
    margin-bottom: 0px;
  }
}

.footer-section {
  background-color: #ffffff;
  padding: 6.25rem 0px;

  .ml-12 {
    margin-left: 12px;
  }

  .company-slug {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #be3052;
  }

  .follow-us {
    margin: 1.5rem 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    color: #be3052;
  }

  .footer-title {
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    color: #be3052;
  }

  .company-address {
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    color: #213864;
  }

  .footer-link {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    color: #213864;
  }
}

.description-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 1.56rem;
  line-height: 157.5%;
  letter-spacing: 0.004em;
  color: #213864;
}

.styled-title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.18rem;
  line-height: 3.25rem;
  color: #213864;
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.styled-title::after {
  width: 2rem;
  height: 2rem;
  opacity: 1;
  content: "";
  position: absolute;
  border-top: 0.875rem solid #be3052;
  border-right: 0.875rem solid #be3052;
  border-radius: 2px;
  right: -1.2rem;
  top: -0.68rem;
}

@media only screen and (max-width: 1600px) {
  .section-bg {
    img {
      float: right;
      width: 78%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .offer-section {
    .card-grid {
      grid-template-columns: repeat(2, 0.23fr);
    }
  }

  .hero-section {
    margin: 12rem 0px;
  }

  .section-bg {
    img {
      float: right;
      width: 70%;
    }
  }

  #rock-left {
    width: 35%;
    top: -12rem !important;
  }

  #right-rock {
    right: -60px !important;
    top: -12rem !important;
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .offer-section {
    .card-grid {
      grid-template-columns: repeat(1, 0.8fr);
    }
  }

  .section-bg {
    img {
      float: right;
      width: 60%;
    }
  }

  .email-section {
    .mob-email {
      margin: 0 auto;
      width: fit-content;
    }
  }
}

.person-name {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.18rem;
  color: #505055;
}

.founder {
  font-style: normal;
  font-weight: normal;
  font-size: 0.81rem;
  line-height: 1.06rem;
  color: #505055;
  opacity: 0.6;
}
.insta-logo {
  max-height: 27px;
  max-width: 28px;
}
