:global {
    .custom-input.ant-input {
        background: #FFFFFF;
        border: 0.5px solid #CECECE;
        box-sizing: border-box;
        border-radius: 8px;
        height: 54px;
    }

    .custom-textArea.ant-input {
        background: #FFFFFF;
        border: 0.5px solid #CECECE;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .custom-label {
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        color: #213864;
        display: inline-block;
        margin-bottom: 4px;
    }
}